.container {
    width: 100%;
    height: 100%;
    // background-color: red;
    overflow: visible;
}

.containerBtn {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.containerHover {
    display: none;
}

.container:hover>.containerHover {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    background-color: beige;
    width: 140px;
    right: 0;
    border-radius: 10px;
    margin-inline: auto;

    .containerData {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        p {
            margin: 0;
        }
    }
}