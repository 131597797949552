@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

* {
    font-family: "Lato", sans-serif;
}

body {
    margin: 0;
    text-decoration: none !important;
}
a{
    text-decoration: none;
}
label {
    font-weight: bold !important;
}
/* input[type=date] { color: transparent !important; } 
.active input[type=date] { color: inherit !important; } */
.MuiDrawer-paperAnchorDockedLeft {
    border-right: none !important;
}

.MuiInputBase-input{
    margin-top: 2px !important;
}

.MuiDataGrid-row:first-of-type {
    border-top: 1px solid #f2f2f2;
}
.MuiCircularProgress-root {
    color: #2B3E4C !important;
}
.MuiDataGrid-row:nth-of-type(even) {
    background-color: #fafafa !important;
}
.MuiDataGrid-row:nth-of-type(odd) {
    background-color: #fff !important;
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected {
    background-color: #a7a7a7 !important;
    /* border: none !important;
    outline: none !important; */
}
.MuiDataGrid-row:nth-of-type(even).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    /* border: none !important;
    outline: none !important; */
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected {
    background-color: #a7a7a7 !important;
    /* border: none !important;
    outline: none !important; */
}
.MuiDataGrid-row:nth-of-type(odd).Mui-selected:hover {
    background-color: #a7a7a7 !important;
    /* border: none !important;
    outline: none !important; */
}

.MuiDataGrid-cell {
    outline: none !important;
    font-size: 16px !important;
}
.MuiDataGrid-cell:hover {
    outline: none !important;
}
.MuiDataGrid-row:hover {
    background-color: #f2f2f2 !important;
}
.MuiDataGrid-columnHeader {
    outline: none !important;
}
input:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.608) !important
}
select:disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.608) !important
}
.MuiDataGrid-columnHeader{
    font-size: 16px !important;
}
.MuiInputBase-root-MuiInput-root::after{
    border-bottom: 2px solid #2B3E4C !important;
}
@media print {
    @page { margin: 0; }
    body { margin: 2cm; }
  }
  