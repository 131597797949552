.root {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.logoContainer {
    width: 100%;
    height: 50px;
    background: #2B3E4C;


    img {
        height: 70px;
        margin-left: 15px;
        margin-top: 5px;

    }
}

div.login {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;

    height: 100vh;
    background: #2B3E4C;

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    div.container {
        width: 600px;

        padding: 40px 0;
        height: 100%;

        div.logoContainer {
            padding: 32px 32px 0;
            background-color: #f7f7f7;
            margin-bottom: 24px;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 32px;
            box-shadow: 0 10px 16px rgb(0 0 0 / 16%);
            width: 100%;

            p {
                text-align: center;
                font-weight: 700;
                font-size: 36px;
                color: #595959;

                span {
                    font-weight: 300;
                }
            }

            img {
                width: 300px;
                -webkit-transform: translateY(-32px);
                transform: translateY(-32px);
            }
        }

        div.qrContainer {
            padding: 32px;
            background-color: #f7f7f7;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -webkit-align-items: center;
            align-items: center;
            border-radius: 32px;
            box-shadow: 0 10px 16px rgb(0 0 0 / 16%);
            width: 100%;
            height: 480px;

            h4 {
                text-align: center;
                margin-bottom: 8px;
                font-weight: 700;
                font-size: 36px;
                color: #2B3E4C;
            }

            p {
                text-align: center;
                margin-bottom: 16px;
                font-size: 24px;
            }

            button.button {
                color: #fff !important;
                background: #2B3E4C;
                border-radius: 21px !important;
                text-transform: none !important;
                font-weight: 400;
                font-family: "Roboto", sans-serif !important;
                margin-top: 2rem;
                border: none;
                padding: 12px;
                text-align: center;
                cursor: pointer;
                font-size: 20px;
                width: 100%;
            }
        }
    }
}