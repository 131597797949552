.inputs {
  margin: 1rem;

  .column {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
    width: 100%;
  }

  .column-sm {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;

    max-width: 30vw;
    width: 100%;
  }
}

.inputsBig {
  width: 100% !important;
  margin: 1rem;

  .column {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
    width: 100%;
  }

  .column-sm {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
    width: 100%;
  }
}