.main {
    align-items: center;
    background-image: url("../../assets/icons/background.png");
    background-position-y: 100%;
    background-size: cover;
    display: flex;
    height: 90vh;
    overflow-y: hidden;
    overflow-x: hidden;

    .section {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .title {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
            margin-top: 50px;

            .welcome {
                color: #2B3E4C;
                font-weight: 700;
                font-size: 2.5em;
            }

            .phrase {
                font-size: 1.5em;
                font-weight: 400;
                width: 100%;
            }
        }

        .serviceContainer {
            display: flex;
            gap: 3em;
            height: 231px;
            justify-content: center;
            margin: 4em 0;
            width: 100%;
        }

        .help {
            background-color: #87B867;
            border-top-left-radius: 50px;
            bottom: 0;
            color: white;
            cursor: pointer;
            font-size: 1.2rem;
            padding: 1.5em;
            position: absolute;
            right: 0;
            text-align: center;
            width: 12%;
        }
    }
}

.section {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    justify-content: center;
    line-height: 0;
    margin-bottom: 4rem;
    width: 100%;
}

.section h4 {
    color: #2B3E4C;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 16px;
}

.section h1 {
    color: #2B3E4C;

    font-weight: 300;
    font-size: 50px;
}

.section h2 {
    color: #00B187;

    font-weight: bold;
    font-size: 50px;
}

.section p {
    color: #2B3E4C;

    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;

    width: 100%;
}

.containerInfo {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 30px;
    align-items: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            display: flex;

            overflow-wrap: break-word;
            flex-wrap: wrap;
            font-size: 13px;
        }

        .iconsNoContratado {
            color: #656D7D;
            font-size: 1.8rem;
        }

        .iconsAutorizado {
            color: #006E54;
            font-size: 1.8rem;
        }

        .iconsContratado {
            color: #006E54;
            font-size: 1.8rem;
        }

        img {
            margin-right: 3px;
        }
    }
}