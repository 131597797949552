.container {
    width: 100%;
    height: 100%;
    // background-color: red;
    overflow: visible;
}

.containerBtn {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.containerHover {
    display: none;
}

.containerHoverCenter {
    display: none;
}

.container:hover>.containerHover {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    background-color: beige;
    width: 140px;
    right: 0;
    border-radius: 10px;
    margin-inline: auto;

    .containerData {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        p {
            margin: 0;
        }
    }
}

.container:hover>.containerHoverCenter {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    background-color: beige;
    width: 140px;
    right: auto;
    border-radius: 10px;
    margin-inline: auto;

    .containerData {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        p {
            margin: 0;
        }
    }
}

.containerValue {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    gap: 10px;

}

.circle {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.yellow {
    background-color: rgb(255, 213, 1);
}

.green {
    background-color: green;
}