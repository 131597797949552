@use "../../variables" as v;

.button {
    background: #87B867 !important;
    border: 0;
    border-radius: 1.5rem !important;
    color: #ffffff !important;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    letter-spacing: 0rem;
    margin: 0.5rem !important;
    opacity: 1;

    text-transform: capitalize !important;
    text-align: center;
    width: 10rem;
    height: 3rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #9ec882 !important;
    }

    &:disabled {
        background: #87B86788 !important;
    }
}