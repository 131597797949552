.container {
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 10px;

    .row {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 98%;
        text-align: start;

        .title {
            margin: 0px;
            display: flex;
            width: 100%;
        }

        .data {
            margin: 0px;
            display: flex;
            flex: 3;
        }
    }
}